<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" align="center" align-items="center">
        <v-hover v-slot="{ isHovering, props }">
          <v-card
            :loading="loading"
            v-bind="props"
            :class="`elevation-${isHovering ? 12 : 4}`"
            class="mx-auto transition-swing"
            color="grey-lighten-2"
          >
          <v-img
            class="align-center text-white"
            height="350"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,1)"
            :src="hero_card.src"
            cover
          >
            <v-card-title class="pa-6 text-h1">Изомат Варна</v-card-title>
            <v-card-subtitle class="pa-6 text-h4">Вашият онлайн магазин за строителни материали</v-card-subtitle>
          </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardPage',

  components: {
  },

  data: () => ({
    hero_card: {
      title: 'Pre-fab homes',
      // src: 'https://img.freepik.com/free-photo/construction-site-silhouettes_1127-3253.jpg',
      src: require('@/assets/hero-background.jpg'),
      flex: 12
    }
  }),

  methods: {
    reserve () {
      this.loading = true

      setTimeout(() => (this.loading = false), 5000)
    }
  }
}
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>
