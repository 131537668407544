<template>
  <v-app>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content}` : `SITE_NAME` }}</template>
    </metainfo>
    <v-main class='mainApp'>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'App',

  setup () {
    useMeta({
      title: 'Строителни Материали Варна',
      htmlAttrs: { lang: 'en', amp: true },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: '' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: '' },
        { name: 'author', content: '' }
      ]
    })
  },

  data: () => ({
    //
  })
}
</script>

<style>
.mainApp{
  overflow:hidden;
}
</style>
