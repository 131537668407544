<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" align-self="auto">
                <v-row>
                <v-col cols="12">
                    <v-hover v-slot="{ isHovering, props }">
                    <v-card
                        :loading="loading"
                        v-bind="props"
                        :class="`elevation-${isHovering ? 24 : 4}`"
                        class="mx-auto pa-6 transition-swing"
                        color="grey-lighten-2"
                    >
                    <v-card-item title="Categories - Categories Management" class="text--primary">
                        <template v-slot:subtitle>
                        Manage Re-Up Categories Database
                        </template>
                    </v-card-item>

                    <v-card-item>
                        <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        filled
                        color='primary'
                        single-line
                        hide-details
                        ></v-text-field>
                    </v-card-item>

                    <v-card-item>
                        <v-data-table v-if="loading"
                        loading
                        loading-text="Loading orders..."
                        no-data-text="Loading users..."
                        color='accent'
                        >
                        </v-data-table>

                        <v-data-table
                        v-else
                        :headers="headers"
                        :items="user_database"
                        :search="search"
                        :sort-by="[{ key: 'protein', order: 'desc' }]"
                        color="primary"
                        multi-sort
                        >
                        <template v-slot:top>
                        <v-toolbar
                            flat
                        >
                            <v-toolbar-title>Categories</v-toolbar-title>
                            <v-dialog
                            v-model="dialog"
                            max-width="500px"
                            >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                color="primary"
                                class=""
                                v-bind="props"
                                variant="tonal"
                                >
                                Create New Category
                                </v-btn>
                            </template>
                            <v-card color="primary">
                                <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-divider></v-divider>

                                <v-card-text>
                                <v-container>
                                    <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        clearable
                                        variant="filled"
                                        color="white"
                                        v-model="editedItem.name"
                                        label="Category Name"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        clearable
                                        variant="filled"
                                        color="white"
                                        v-model="editedItem.color"
                                        label="Category Color"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        filled
                                        v-model="editedItem.position"
                                        label="Category Position"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-checkbox
                                        filled
                                        color="white"
                                        v-model="editedItem.isActive"
                                        label="Category Active"
                                        ></v-checkbox>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                </v-card-text>

                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="white"
                                    variant="text"
                                    @click="close"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="white"
                                    variant="text"
                                    @click="save"
                                >
                                    Create
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue-darken-1" variant="text" @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        </v-toolbar>
                        </template>
                        <template v-slot:[`item.isActive`]="{ item }">
                          <v-checkbox-btn
                            v-model="item.value.isActive"
                            disabled
                          ></v-checkbox-btn>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            size="small"
                            class="me-2"
                            @click="editItem(item.raw)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            size="small"
                            @click="deleteItem(item.raw)"
                        >
                            mdi-delete
                        </v-icon>
                        </template>
                        <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initialize"
                        >
                            Reset
                        </v-btn>
                        </template>
                        </v-data-table>
                    </v-card-item>

                    </v-card>
                    </v-hover>
                </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AccountManagementPage',

  components: {
  },

  data: () => ({
    search: '',
    loading: false,

    dialog: false,
    dialogDelete: false,
    formTitle: 'Create New Category',
    headers: [
      {
        title: 'Name',
        align: 'start',
        key: 'name'
      },
      { title: 'Color', key: 'color' },
      { title: 'Position', key: 'position' },
      { title: 'Activated', key: 'isActive' },
      // { title: 'Date Created', key: 'dateCreated' },
      { title: 'Actions', key: 'actions', sortable: false }
    ],
    user_database: [
      {
        name: 'Sport',
        color: '#fefefe',
        position: 0,
        isActive: true,
        dateCreated: 4.0
      },
      {
        name: 'Scenes',
        color: '#fefefe',
        position: 1,
        isActive: true,
        dateCreated: 4.0
      },
      {
        name: 'Other',
        color: '#fefefe',
        position: 2,
        isActive: true,
        dateCreated: 4.0
      }
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  methods: {
    async initialize () {
      this.user_database = [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3
        }
      ]
    },

    editItem (item) {
      this.editedIndex = this.user_database.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.user_database.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.user_database.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.user_database[this.editedIndex], this.editedItem)
      } else {
        this.user_database.push(this.editedItem)
      }
      this.close()
    },

    async getAcccoundsDB () {
      this.loading = true
      const response = await axios.get('/home/manage/accounts')
      console.log(response)
      // setTimeout(() => (this.loading = false), 2000)
      this.loading = false
    },

    async testConnection () {
      this.loading = true
      const response = await axios.get('/test-connection')
      console.log(response)
      setTimeout(() => (this.loading = false), 2000)
    }
  },

  created () {
    // this.initialize()
  },

  mounted () {
    this.testConnection()
    // this.getAcccoundsDB()
  }
}
</script>
<style scoped>
.login-fix-page {
padding-bottom: 7em;
padding-top: 4em;
}
</style>
