<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="12" align-self="auto">
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                v-bind="props"
                :class="`elevation-${isHovering ? 24 : 1}`"
                class="mx-auto pa-6 transition-swing"
                color="secondary">
                  <v-card-item title="Свържете се с нас"
                  class="text--primary">
                      <template v-slot:subtitle>
                      Можете да се свържете с нас по телефон, имейл или използвайки формата по-долу.
                      </template>
                  </v-card-item>
                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="6" md="8" align-self="auto">
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                :loading="sending_message"
                v-bind="props"
                :class="`elevation-${isHovering ? 24 : 1}`"
                class="mx-auto pa-6 transition-swing"
                color="grey-lighten-2">
                  <v-card-item title="Форма за връзка"
                  class="text--primary">
                      <template v-slot:subtitle>
                      Попълнете име, телефон, имейл адрес и вашето запитване.
                      </template>
                  </v-card-item>

                  <v-card-item>
                    <v-expand-transition ease-in>
                      <v-row v-if='support_alert_flag' align='center' justify='center' style="margin-top:15px;">
                        <v-col cols='12' xs='12' sm='12' md='12'>
                            <v-alert
                            :type="support_alert_type">
                            {{support_alert_message}}
                            </v-alert>
                        </v-col>
                      </v-row>
                    </v-expand-transition>
                  </v-card-item>

                  <v-card-item id="form">
                    <v-form
                    ref="support_form"
                    @submit.prevent=""
                    @submit="sendEmail"
                    lazy-validation>

                      <v-row dense align='center' justify='center'>
                        <v-col cols='12' xs='12' sm='12' md='12'>
                          <v-text-field
                          required
                          :rules="support_validate_name_rules"
                          v-model="contact_Name"
                          background-color='white'
                          color="primary"
                          filled rounded
                          dense
                          label="Име"
                          ></v-text-field>
                        </v-col>
                        <v-col cols='12' xs='12' sm='12' md='7'>
                          <v-text-field
                          required
                          :rules="support_validate_email_rules"
                          v-model="contact_Email"
                          background-color='white'
                          color="primary"
                          filled rounded
                          dense
                          label="Поща"
                          ></v-text-field>
                        </v-col>
                        <v-col cols='12' xs='12' sm='12' md='5'>
                          <v-text-field
                          required
                          :rules="support_validate_phone_rules"
                          v-model="contact_Phone"
                          background-color='white'
                          color="primary"
                          filled rounded
                          dense
                          label="Телефон"
                          ></v-text-field>
                        </v-col>
                        <v-col cols='12' xs='12' sm='12' md='12'>
                          <v-textarea
                          required
                          :rules="support_validate_message_rules"
                          v-model="contact_Message"
                          background-color='white'
                          color="primary"
                          filled rounded dense
                          label="Вашето запитване"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row dense align='center' justify='center'>
                        <v-col cols='12' xs='12' sm='12' md='12'>
                          <v-btn
                          block
                          rounded
                          color="primary"
                          type="submit"
                          :disabled="completed_message"
                          :loading="sending_message">
                          Изпрати
                            <template v-slot:loader>
                              <span>Заявката се обработва <v-progress-circular indeterminate></v-progress-circular> </span>
                            </template>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-item>

                </v-card>
              </v-hover>
            </v-col>
            <v-col cols="6" md="4" align-self="auto">
              <v-hover v-slot="{ isHovering, props }">
                <v-card
                v-bind="props"
                :class="`elevation-${isHovering ? 24 : 1}`"
                class="mx-auto pa-6 transition-swing"
                color="grey-lighten-2">
                  <v-card-item title="Свържете се директно с нас"
                  class="text--primary">
                  </v-card-item>

                  <v-card-text class="py-2">
                    <v-row align="center" no-gutters>
                      <v-col cols="2" class="text-left">
                        <v-icon
                          color="primary"
                          icon="mdi-phone"
                          size="35"
                        ></v-icon>
                      </v-col>

                      <v-col
                        class="text-h6"
                        cols="10"
                      >
                        +359 897 000 000
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="py-2">
                    <v-row align="center" no-gutters>
                      <v-col cols="2" class="text-left">
                        <v-icon
                          color="primary"
                          icon="mdi-mail"
                          size="35"
                        ></v-icon>
                      </v-col>

                      <v-col
                        class="text-h6"
                        cols="10"
                      >
                        support@isomatvarna.bg
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AccountManagementPage',

  components: {
  },

  data: () => ({
    loading: false,
    sending_message: false,
    completed_message: false,
    support_alert_flag: false,
    support_alert_type: '',
    support_alert_message: '',

    // Data
    contact_Name: '',
    contact_Email: '',
    contact_Phone: '',
    contact_Message: '',

    support_validate_name_rules: [
      v => !!v || 'Полето е задължително',
      v => !/([0-9])/.test(v) || 'Полето не може да съдържа цифри'
    ],
    support_validate_phone_rules: [
      v => !!v || 'Полето е задължително',
      v => v.length <= 15 || 'Номерът е твърде дълъг',
      v => /([0-9+()])/.test(v) || 'Полето може да съдържа само цифри'
    ],
    support_validate_email_rules: [
      v => !!v || 'Полето е задължително',
      v => /.+@.+\..+/.test(v) || 'Невалидна ел. поща',
      v => /([A-Za-z])\w/.test(v) || 'Пощата трябва да е на латиница'
    ],
    support_validate_message_rules: [
      v => !!v || 'Полето е задължително'
    ]
  }),

  watch: {
  },

  methods: {
    async testConnection () {
      this.loading = true
      // const response = await axios.get('/test-connection')
      // console.log(response)
      setTimeout(() => (this.loading = false), 2000)
    },
    async sendEmail () {
      this.sending_message = true
      const isFormReady = this.$refs.support_form.validate()
      if (isFormReady === true) {
        const payload = {
          name: this.contact_Name,
          email: this.contact_Email,
          phone: this.contact_Phone,
          message: this.contact_Message
        }
        const response = await axios.post('/support', payload)
        console.log(response)

        if (response.data.status === 'send') {
          this.sending_message = false
          this.completed_message = true
          this.support_alert_flag = true
          this.support_alert_type = 'success'
          this.support_alert_message = 'Успешно изпратихте Вашето запитване!'
        } else {
          this.sending_message = false
          this.support_alert_flag = true
          this.support_alert_type = 'error'
          this.support_alert_message = 'Не успяхме да регистрираме Вашето запитване. Моля опитайте отново или се свържете директно с нас!'
        }
      } else {
        this.sending_message = false
        this.support_alert_flag = true
        this.support_alert_type = 'info'
        this.support_alert_message = 'Не сте попълнили всички полета по оказания начин'
      }
      this.$vuetify.goTo('#id')
    }
  },

  created () {
    // this.initialize()
  },

  mounted () {
    this.testConnection()
    // this.getAcccoundsDB()
  }
}
</script>
<style scoped>
.login-fix-page {
padding-bottom: 7em;
padding-top: 4em;
}
</style>
