<template>
    <!-- <v-container> -->
      <v-navigation-drawer
        v-model="drawer"
        color="primary"
        scrim
        elevation="21"
        permanent
        @dblclick="drawer = false"
      >
        <v-list density="default" nav>
          <v-list-item
          value="inbox"
          to="/dashboard">
          <v-img
            contain
            height="125"
            :src="require('../../public/img/logo.png')"
          ></v-img></v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="default" nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :title="item.title"
            :subtitle="item.subtitle"
            :to="item.to"
            :prepend-icon="item.prependIcon"
            :active-color="item.activeColor"
          ></v-list-item>
        </v-list>

        <!-- <v-divider></v-divider> -->

        <!-- <v-list density="default" nav>
          <v-list-item prepend-icon="mdi-phone" title="+359 886 92 66 33"></v-list-item>
          <v-list-item prepend-icon="mdi-mail" title="support@pratkabg.com"></v-list-item>
        </v-list> -->
        <!-- <v-list density="default" nav>
          <v-list-item
            v-for="item in footer_items"
            :key="item.title"
            :title="item.title"
            :subtitle="item.subtitle"
            :to="item.to"
            :prepend-icon="item.prependIcon"
            :active-color="item.activeColor"
          ></v-list-item>
        </v-list> -->

      </v-navigation-drawer>

      <v-app-bar
        color="transparent"
        prominent
        permanent
      >
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <!-- <v-toolbar-title>{{ page_title }}</v-toolbar-title> -->

        <v-spacer></v-spacer>
        <v-btn style="margin:5px;" variant="tonal" to="/checkout" icon="mdi-cart"></v-btn>
        <!-- <v-btn style="margin:5px;" variant="tonal" to="/login" icon="mdi-door-open"></v-btn> -->
      </v-app-bar>
    <!-- </v-container> -->
</template>

<script>
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  name: 'NavigationBar',

  data () {
    return {
      // Flags
      drawer: true,
      items: [
        {
          title: 'Начало',
          // subtitle: 'Начален панел',
          value: 'dashboard',
          to: '/dashboard',
          prependIcon: 'mdi-view-dashboard',
          activeColor: 'secondary'
        },
        {
          title: 'За нас',
          // subtitle: 'Кои сме ние и какво предлагаме',
          value: 'about',
          to: '/about-us',
          prependIcon: 'mdi-information',
          activeColor: 'secondary'
        },
        {
          title: 'Продукти',
          subtitle: 'Каталог и поръчка',
          value: 'products',
          to: '/products',
          prependIcon: 'mdi-package',
          activeColor: 'secondary'
        },
        {
          title: 'Контакти',
          // subtitle: 'Адресна книга',
          value: 'contact',
          to: '/contact-us',
          prependIcon: 'mdi-map-marker',
          activeColor: 'secondary'
        }
      ]
    }
  },

  computed: {
    page_title () {
      const route = useRoute()
      const path = computed(() => route.name)
      return path.value
    }
  },

  mounted () {
  }
}
</script>

<style scoped>
</style>
