// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { md3 } from 'vuetify/blueprints'
import colors from 'vuetify/lib/util/colors'

// Vuetify
import { createVuetify } from 'vuetify'
import { VDataTable } from 'vuetify/labs/VDataTable'

// Translation Files
import en from '@/i18n/en.json'

// Тhemes
const lightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#ED1C2E',
    secondary: '#231F20',
    third: colors.grey.lighten3,
    accent: '#3692b6',
    text: '#1F506F',
    error: colors.red.accent4,
    warning: colors.amber.darken2,
    info: colors.grey.darken1,
    success: colors.green.darken1
  }
}
const darkTheme = {
  dark: true,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#6200EE',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00'
  }
}

export default createVuetify({
  ssr: true,
  locale: {
    locale: 'en',
    fallback: 'en',
    messages: { en }
  },
  defaults: {
    global: {
      ripple: false
    },
    VSheet: {
      elevation: 4
    }
  },
  blueprint: md3,
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme,
      darkTheme
    }
  },
  components: {
    VDataTable
  }
} // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
