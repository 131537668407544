<template>
    <v-container>
      <v-row>
        <v-col cols="12" md="12" align-self="auto">
          <v-card
              :loading="loading"
              v-bind="props"
              :class="`elevation-${isHovering ? 24 : 4}`"
              class="mx-auto pa-6 transition-swing"
              color="grey-lighten-2"
          >
          <v-card-item title="Очаквайте скоро!" class="text--primary">
              <template v-slot:subtitle>
              В момента разработваме страницата!
              </template>
          </v-card-item>

          </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AccountManagementPage',

  components: {
  },

  data: () => ({
    search: '',
    loading: false,

    dialog: false,
    dialogDelete: false,
    formTitle: 'Create New User',
    headers: [
      {
        title: 'Email',
        align: 'start',
        key: 'name'
      },
      { title: 'Date Of Birth', key: 'fat' },
      { title: 'Phone Number', key: 'carbs' },
      { title: 'Joined At', key: 'protein' },
      { title: 'Actions', key: 'actions', sortable: false }
    ],
    user_database: [
      {
        name: 'Frozen Yogurt',
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0
      }
    ],
    editedIndex: -1,
    editedItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  methods: {
    async initialize () {
      this.user_database = [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3
        }
      ]
    },

    editItem (item) {
      this.editedIndex = this.user_database.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.user_database.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.user_database.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.user_database[this.editedIndex], this.editedItem)
      } else {
        this.user_database.push(this.editedItem)
      }
      this.close()
    },

    async getAcccoundsDB () {
      this.loading = true
      const response = await axios.get('/home/manage/accounts')
      console.log(response)
      // setTimeout(() => (this.loading = false), 2000)
      this.loading = false
    },

    async testConnection () {
      this.loading = true
      const response = await axios.get('/test-connection')
      console.log(response)
      setTimeout(() => (this.loading = false), 2000)
    }
  },

  created () {
    this.initialize()
  },

  mounted () {
    this.testConnection()
    // this.getAcccoundsDB()
  }
}
</script>
<style scoped>
.login-fix-page {
padding-bottom: 7em;
padding-top: 4em;
}
</style>
