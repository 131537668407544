// Import middleware
// import auth from '@/middleware/auth'
// import guest from '@/middleware/guest'

// Views/Layouts
// import AuthLayout from '../views/AuthView.vue'
import DashboardLayout from '../views/DashboardView.vue'

// Pages
// import Login from '../pages/Login.vue'
import Dashboard from '../pages/Dashboard.vue'
// ---
// import Profile from '../pages/Profile.vue'
// ---
import Cart from '../pages/Cart.vue'
import Empty from '../pages/Empty.vue'
// import SendParcel from '../pages/SendParcel.vue'
// import TrackParcel from '../pages/TrackParcel.vue'
import ContactUs from '../pages/ContactUs.vue'
// ---

// const authPages = {
//   path: '/',
//   component: AuthLayout,
//   name: 'Authentication',
//   children: [
//     {
//       path: '/login',
//       name: 'Login',
//       component: Login,
//       meta: { middleware: guest }
//     }
//     // {
//     //   path: '/register',
//     //   name: 'Register',
//     //   component: Register,
//     //   meta: { middleware: guest }
//     // }
//   ]
// }

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home'
  },
  {
    path: '/',
    component: DashboardLayout,
    // meta: { middleware: auth },
    children: [
      {
        path: 'dashboard',
        name: 'Начало',
        components: { default: Dashboard }
        // meta: { middleware: auth }
      },
      // {
      //   path: 'profile',
      //   name: 'Profile',
      //   components: { default: Profile }
      //   // meta: { middleware: auth }
      // },
      {
        path: 'about-us',
        name: 'За нас',
        components: { default: Empty }
        // meta: { middleware: auth }
      },
      {
        path: 'products',
        name: 'Продукти',
        components: { default: Empty }
        // meta: { middleware: auth }
      },
      {
        path: 'checkout',
        name: 'Каса',
        components: { default: Cart }
        // meta: { middleware: auth }
      },
      {
        path: 'contact-us',
        name: 'Контакти',
        components: { default: ContactUs }
        // meta: { middleware: auth }
      }
    ]
  }
  // authPages
]

export default routes
